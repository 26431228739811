
  import { defineComponent, onMounted, onBeforeMount } from 'vue'
  import { setCurrentPageBreadcrumbs } from '@src/core/helpers/breadcrumb'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'

  import { useStore } from 'vuex'
  import settingsStore from '@src/store/enums/settings'

  export default defineComponent({
    name: 'TasksIndex',
    setup () {
      const store = useStore()

      onBeforeMount(() => {
        // store.dispatch(settingsStore.Actions.SET_ACTIVE_SUBMENU, 'Tasks')
        store.dispatch(settingsStore.Actions.SET_ACTIVE_SUBMENU, 'Global')
      })

      onMounted(() => {
        setCurrentPageBreadcrumbs(
          trans('nav.aside.tasks.index'),
          [
            capitalize(transChoice('global.task', 2))
          ]
        )
      })
    }
  })
